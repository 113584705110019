<template>
  <div class="content">
    <div class="bannerView">
      <img class="img" :src="banner.banner" alt="" />
      <div class="bannerMain">
        <router-link to="/about" class="link"
          ><i class="el-icon-arrow-left"></i>關於博銳</router-link
        >
        <div class="txtView">
          <h3>{{ banner.title }}</h3>
          <div
            v-if="banner.content"
            class="con html-view"
            v-html="banner.content.replace(/\\r\\n/g, '<br>')"
          ></div>
        </div>
      </div>
    </div>
    <div class="main">
      <el-row>
        <div
          v-for="(item, index) of list"
          :key="index"
          :class="index > 2 ? 'min_col' : ''"
        >
          <el-col :span="24" v-if="index == 0">
            <router-link
              :to="`/operation_base_detailed?id=${item.id}`"
              class="item item_one"
            >
              <div class="imgView">
                <img :src="item.attachment" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
                <div class="txtCon">{{ item.content }}</div>
              </div>
            </router-link>
          </el-col>
          <el-col :span="8" v-else-if="index == 1">
            <router-link
              :to="`/operation_base_detailed?id=${item.id}`"
              class="item item_two"
            >
              <div class="imgView">
                <img :src="item.attachment" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
          </el-col>
          <el-col :span="16" v-else-if="index == 2" class="dr">
            <router-link
              :to="`/operation_base_detailed?id=${item.id}`"
              class="item item_three"
            >
              <div class="imgView">
                <img :src="item.attachment" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
          </el-col>
          <el-col :span="24" v-else>
            <router-link
              :to="`/operation_base_detailed?id=${item.id}`"
              class="item item_four"
            >
              <div class="imgView">
                <img :src="item.attachment" alt="" />
              </div>
              <div class="txtView">
                <div class="upView">
                  <span>{{ item.sub_title }}</span>
                </div>
                <h3>{{ item.title }}</h3>
              </div>
            </router-link>
          </el-col>
        </div>
      </el-row>
      <!-- <el-button class="see_more" @click="getData" round>查看更多</el-button> -->
    </div>
    <div class="subscribeNewsView">
      <subscribe-news />
    </div>
  </div>
</template>

<script>
import subscribeNews from "@/components/subscribeNews";
export default {
  name: "OperationBase",
  components: {
    subscribeNews,
  },
  data() {
    return {
      banner: {},
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.operationBase().then((res) => {
        if (res.code == 200) {
          this.banner = res.data.banner;
          this.list = this.list.concat(res.data.list);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
@import "@/assets/style/news.scss";
.content {
  @include respond-to(md) {
    padding-top: 30px;
  }
  @include respond-to(sm) {
    padding-top: 15px;
  }
}
.bannerView {
  position: relative;
  margin-bottom: 80px;
  @include respond-to(md) {
    display: none;
  }
  .img {
    width: 100%;
    display: block;
  }
  .bannerMain {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding-left: 270px;
    padding-top: 30px;
    @include respond-to(max1439) {
      padding-left: 100px;
    }

    .link {
      color: #fff;
      font-size: 14px;
      display: inline-block;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
    .txtView {
      margin-top: 70px;
      width: 220px;
      color: #fff;
      @include respond-to(max1439) {
        margin-top: 30px;
      }
      h3 {
        font-size: 40px;
        margin-bottom: 20px;
        font-weight: normal;
        @include respond-to(max1439) {
          font-size: 36px;
          margin-bottom: 10px;
        }
        @include respond-to(lg) {
          font-size: 26px;
        }
      }
      .con {
        font-size: 18px;
        line-height: 1.8;
        @include respond-to(max1439) {
          font-size: 16px;
        }
        @include respond-to(lg) {
          font-size: 14px;
        }
      }
    }
  }
}
.main {
  .item {
    &:hover {
      .txtView {
        h3 {
          color: #0075c1;
        }
      }
    }
  }
}
</style>